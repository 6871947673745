import React from "react";
import { useTranslation } from "react-i18next";
import { Tabs } from "@mantine/core";
import ProductData from "./ProductData";
import ProductDiscounts from "./ProductDiscounts";

const Product = () => {
  const [_] = useTranslation("global");

  return (
    <div
      class="add-product-container d-flex flex-column gap-2 my-3 px-4 "
      style={{ flexGrow: 4, position: "relative" }}
    >
      <Tabs defaultValue="editProduct" className="w-100">
        <Tabs.List grow>
          <Tabs.Tab
            value="editProduct"
            className="fs-5"
            color="rgb(89, 198, 201)"
          >
            {_("panel.editProduct")}
          </Tabs.Tab>
          <Tabs.Tab
            value="productDiscounts"
            className="fs-5"
            color="rgb(89, 198, 201)"
          >
            {_("panel.productDiscounts")}
          </Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="editProduct">
          <ProductData />
        </Tabs.Panel>

        <Tabs.Panel value="productDiscounts">
          <ProductDiscounts />
        </Tabs.Panel>
      </Tabs>
    </div>
  );
};

export default Product;
