import React from "react";
import { useTranslation } from "react-i18next";
import footerImg from "../../imgs/static_home_images/footer-logo.png";
import {
  FaFacebookF,
  FaInstagram,
  FaYoutube,
  FaLinkedinIn,
  FaTiktok,
} from "react-icons/fa";
import { Col, Container, Row } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";

export default function Footer() {
  const [_] = useTranslation("global");
  const { lang, slug } = useParams();
  const navigate= useNavigate();
  const token = localStorage.getItem("token");

  const navLinks1 = [
    {
      key: "supplier-register",
      delay: "0",
      href: `/${lang}/auth/register/seller`,
    },
    {
      key: "marketer-register",
      delay: "100",
      href: `/${lang}/auth/register/marketer`,
    },
  ];
  const socialLinks = [
    {
      icon: <FaTiktok />,
      alt: "TikTok",
      href: "https://www.tiktok.com/@ecombo.ae",
      delay: "400",
    },
    {
      icon: <FaLinkedinIn />,
      alt: "LinkedIn",
      href: "https://www.linkedin.com/company/ecombonetwork/",
      delay: "300",
    },
    {
      icon: <FaYoutube />,
      alt: "YouTube",
      href: "https://www.youtube.com/@ecombo",
      delay: "200",
    },
    {
      icon: <FaInstagram />,
      alt: "Instagram",
      href: "https://www.instagram.com/ecombo.ae/",
      delay: "100",
    },
    {
      icon: <FaFacebookF />,
      alt: "Facebook",
      href: "https://www.facebook.com/Ecombo.ae/",
      delay: "0",
    },
  ];
  const navLinks2 = [
    {
      key: "returnAndExchangePolicy",
      delay: "400",
      href: `/${slug}/${lang}/return-and-exchange-policy`,
    },
    {
      key: "paymentAndDeliveryOptions",
      delay: "500",
      href: `/${slug}/${lang}/payment-and-delivery-options`,
    },
    {
      key: "privacyPolicy",
      delay: "600",
      href: `/${slug}/${lang}/privacy-policy`,
    },
    ...(token ? [{
      key: "contactus",
      delay: "700",
      href: `/${slug}/${lang}/contact-us`,
    }] : []), 
  ];
  return (
    <footer
      dir={lang === "en" ? "ltr" : "rtl"}
      className="mt-5 pt-5 text-white"
      style={{ background: "#172B4D" }}
    >
      <Container>
        <div className="mb-4">
          <img onClick={()=>navigate('/')}  style={{cursor:"pointer"}}  src={footerImg} width={150} height={44} alt="footer-logo" />
        </div>
        <Row className="gy-4">
          <Col md={6}>
            <p data-aos="fade-up">{_("static_home.footer.description")}</p>
          </Col>
          <Col md={6} className="d-flex justify-content-around">
            <div>
              {navLinks1.map((link, index) => (
                <Link
                  key={index}
                  to={link.href}
                  rel="noopener noreferrer"
                  data-aos="fade-up"
                  data-aos-delay={link.delay}
                  className="d-block mb-2 text-white  text-decoration-none"
                >
                  {_(`static_home.footer.navLinks1.${link.key}`)}
                </Link>
              ))}
            </div>
            <div>
              {navLinks2.map((navLink, idx) => (
                <Link
                  key={idx}
                  to={navLink.href}
                  data-aos="fade-up"
                  data-aos-delay={navLink.delay}
                  className="d-block mb-2 text-white text-decoration-none"
                >
                  {_(`home.${navLink.key}`)}
                </Link>
              ))}
            </div>
          </Col>
        </Row>
        <Row className="mt-4 justify-content-center">
          {socialLinks.map((social, index) => (
            <Col xs="auto" key={index}>
              <Link to={social.href} target="_blank">
                <span className="fs-4 text-white"> {social.icon}</span>
              </Link>
            </Col>
          ))}
        </Row>
      </Container>
      <div className="border-top mt-4" style={{ background: "#172B4D" }}>
        <Container className="py-3">
          <Row className="justify-content-between">
            <Col xs="auto">
              <p
                className="mb-0 text-white"
                data-aos="fade-up"
                data-aos-delay={300}
              >
                {_("static_home.footer.copy-right")}
              </p>
            </Col>
            <Col xs="auto">
              <p
                className="mb-0 text-white"
                data-aos="fade-up"
                data-aos-delay={300}
              >
                {_("static_home.footer.all-rights")}
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </footer>
  );
}
