import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import TableLabels from "./TableLabels";
import IconsGen from "../../icons_generator/IconsGen";
import { notifyError, notifySuccess } from "../../toastify/toastify";
import ConfirmModal from "../../modals/ConfirmModal";
import AxiosController from "../../authentication/AxiosController";

const SuppliersTableRow = ({ payments_list, refreshData }) => {
  console.log(payments_list);
  const { slug, lang } = useParams();
  const [_] = useTranslation("global");
  const [id, setId] = useState(null);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const token = localStorage.getItem("token");

  const handleCloseModal = () => {
    setShowConfirmModal(false);
  };

  const handleConfirmDelete = () => {
    AxiosController.delete(`api/${slug}/${lang}/remove-secret/${id} `, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        notifySuccess(_("panel.delete_successful"));
        refreshData();
        handleCloseModal();
      })
      .catch((e) => {
        notifyError(e.message);
        handleCloseModal();
      });
  };

  const handleShowModal = (id) => {
    setShowConfirmModal(true);
    setId(id);
  };

  return (
    <div
      className={`table-responsive my-3 d-flex ${
        lang === "en" ? "flex-row" : "flex-row-reversed"
      } align-items-center`}
    >
      <ConfirmModal
        showModal={showConfirmModal}
        setShowModal={setShowConfirmModal}
        action={handleConfirmDelete}
        modalBody={`deleteSure`}
        modalHeader="deleteProduct"
        confirmButton="confirm"
      />
      <table className="table table-borderless" htmlFor="flexCheckDefault">
        <thead>
          <TableLabels />
        </thead>
        <tbody>
          {payments_list.map((order) => {
            const dateSegments = order["created_at_formatted"].split(" ");

            return (
              <tr className="">
                <td key={order.id}>
                  <div
                    className="form-check mt-3 d-flex justify-content-center align-items-center"
                    style={{ paddingLeft: "2.24rem", paddingRight: "7px" }}
                  >
                    <input
                      className="form-check-input"
                      style={{
                        border: "1px solid rgba(243, 168, 55, 1)",
                        transform: "translate(-0%, -50%)",
                      }}
                      type="checkbox"
                      value=""
                      id="flexCheckDefault"
                    />
                  </div>
                </td>
                <td scope="row">
                  {dateSegments[0]} <br /> {dateSegments[1]} {dateSegments[2]}
                </td>
                <td>{order["secretKey"]}</td>
                <td
                  className="align-middle px-3 d-flex justify-content-end "
                  style={{ marginBottom: "-25px" }}
                  onClick={() => handleShowModal(order["id"])}
                >
                  {<IconsGen icon_name={"delete"} />}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default SuppliersTableRow;
