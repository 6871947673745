import React from "react";
import './static_home.css';
import { Accordion } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

export default function CommonQuestions() {
  const { t } = useTranslation("global");
  const { lang } = useParams();

  const questions = [
    {
      key: "item1",
      delay: "100",
    },
    {
      key: "item2",
      delay: "200",
    },
    {
      key: "item3",
      delay: "300",
    },
    {
      key: "item4",
      delay: "400",
    },
    {
      key: "item5",
      delay: "500",
    },
    {
      key: "item6",
      delay: "600",
    },
    {
      key: "item7",
      delay: "700",
    },
  ];

  return (
    <section dir={lang === "en" ? "ltr" : "rtl"} className="py-5 overflow-hidden" id="common-questions">
      <div className="container text-center mb-5">
        <h2 className="ecombo-primary fw-bold">
          {t("static_home.commonQuestions.title")}
        </h2>
      </div>

      <div className="container mt-5">
        <div className="mx-auto" style={{ maxWidth: "600px" }}>
          <Accordion>
            {questions.map((item, index) => (
              <Accordion.Item
                key={index}
                eventKey={index.toString()}
                data-aos="fade-up"
                data-aos-delay={item.delay}
              >
                <Accordion.Header>
                  {t(`static_home.commonQuestions.${item.key}.question`)}
                </Accordion.Header>
                <Accordion.Body>
                  {t(`static_home.commonQuestions.${item.key}.answer`)}
                </Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
        </div>
      </div>
    </section>
  );
}
