import React from 'react' 
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'

const ProductDiscounts = ({slug, lang}) => {
    const [_] = useTranslation('global');
  return (
    <li>
       
    <NavLink to={`/${slug}/${lang}/panel/products/product-discounts`}>
      <p className="d-inline ">{_("panel.productDiscounts")}</p>
    </NavLink>
  </li>
  )
}

export default ProductDiscounts
