import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import { Editor } from "primereact/editor";

import "./AddProduct.css";
import { Alert, Button, Form } from "react-bootstrap";
import VariantsProps from "./Components/VariantsProps";
import BaseServerUrl from "../../authentication/BaseServerUrl";
import AxiosController from "../../authentication/AxiosController";
import IconsGen from "../../icons_generator/IconsGen";
// import Sizes from "../../authentication/Sizes";
import Loader from "../../loader/Loader";
import ProductImgsSlider from "./Components/ProductImgsSlider";
import DescriptionEditor from "./DescriptionEditor";
import ConfirmModal from "../../modals/ConfirmModal";

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const fetchCategories = (slug, lang, token, handleCategories, handleLoader) => {
  handleLoader(true);
  AxiosController.get(`api/${slug}/${lang}/categories`, {
    headers: { Authorization: `Bearer ${token}` },
  })
    .then((res) => {
      handleCategories(res.data);
      handleLoader(false);
    })
    .catch((e) => {
      handleLoader(false);
    });
};

const ProductData = () => {
  const { slug, lang, productId } = useParams();

  const token = localStorage.getItem("token");
  const role = localStorage.getItem("role");
  const employee_type = localStorage.getItem("employee_type");

  const [instanceObject, setInstanceObj] = useState({
    quantity: 0,
    warehouse: 0,
    quantityErr: 0,
    warehouseErr: "",
    seller_commision: 1,
    seller_commisionErr: "",
    sys_commision: 1,
    sys_commisionErr: "",
    is_approved: 0,
    is_approvedErr: "",
    sku:""
  });

  // ProductData data
  const [productName, handleProductName] = useState("");
  const [productENName, handleENProductName] = useState("");
  const [uploadedProductId2, handleUploadedProductId] = useState(0);
  let uploadedProductId = 0;
  const [productCat, handleProductCat] = useState("");
  const [productDesc, handleProductDesc] = useState("");
  const [productENDesc, handleENProductDesc] = useState("");
  const [productDrive, handleProductDrive] = useState("");

  const [success, handleSuccess] = useState("");

  const [productNameErr, handleProductNameErr] = useState("");
  const [productCatErr, handleProductCatErr] = useState("");
  const [productDescErr, handleProductDescErr] = useState("");
  const [productDriveErr, handleProductDriveErr] = useState("");
  const [productImgsErr, handleProductImgsErr] = useState("");
  const [productImgs, handleProductImgs] = useState([]);
  // const [productVariants, handleProductVariants] = useState([
  //   Object.assign({}, instanceObject),
  // ]);
  const [productVariants, handleProductVariants] = useState([
    { ...instanceObject },
  ]);
  const [categories, handleCategories] = useState([]);
  const [productThumb, handleProductThumb] = useState("");

  const pond = useRef(null);

  const [files, setFiles] = useState([]);
  const [Sizes, setSizes] = useState([]);
  const [Colors, setColors] = useState([]);
  const [_] = useTranslation("global");
  const [variantsToDelete, setVariantsToDelete] = useState([]);
  const [loading, handleLoader] = useState(true);

  const acceptedFileTypes = [
    "image/jpeg",
    "image/png",
    "image/gif",
    "image/jpg",
  ];
  const box_head_dir = lang === "en" ? "ltr" : "rtl";

  const create_product = () => {
    handleLoader(true);
    handleProductImgsErr("");
    let valid = true;
    if (valid) {
      AxiosController.put(
        `api/${slug}/${lang}/product/update-product`,
        {
          id: productId,
          title: productName,
          en_title: productENName,
          google_drive_link: productDrive,
          description: productDesc,
          en_description: productENDesc,
          category_id: productCat,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
        .then((res) => {
          handleUploadedProductId(res.data.product_id);
          uploadedProductId = res.data.product_id;
          handleProductNameErr("");
          handleProductCatErr("");
          handleProductDescErr("");
          handleProductDriveErr("");
          handleSuccess(_("panel.productUpdated"));
  
          const deletePromises = variantsToDelete.map(id => 
            AxiosController.put(
              `api/${slug}/${lang}/variety/delete-variety`,
              { id },
              { headers: { Authorization: `Bearer ${token}` } }
            )
          );
  
          const updatePromises = productVariants.map((variant) => {
            if (variant.id) {
              return AxiosController.put(
                `api/${slug}/${lang}/variety/update-variety`,
                { ...variant,product_id: productId, },
                { headers: { Authorization: `Bearer ${token}` } }
              );
            } else {
              // Create new variant
              return AxiosController.post(
                `api/${slug}/${lang}/variety/add-variety`,
                { ...variant,product_id: productId, },
                { headers: { Authorization: `Bearer ${token}` } }
              );
            }
          });
  
          Promise.all([...deletePromises, ...updatePromises])
            .then(() => {
              setVariantsToDelete([]);
              fetchProduct();
              return pond.current.processFiles();
            })
            .then(() => {
              // Files have been processed
              handleLoader(false);
            })
            .catch((error) => {
              // Handle any errors that occurred during the requests or file processing
              console.error("An error occurred:", error);
              handleLoader(false);
            });
        })
        .catch((e) => {
          handleProductNameErr(
            e.response.data.error.title ? e.response.data.error.title[0] : ""
          );
          handleProductCatErr(
            e.response.data.error.category_id
              ? e.response.data.error.category_id[0]
              : ""
          );
          handleProductDescErr(
            e.response.data.error.description
              ? e.response.data.error.description[0]
              : ""
          );
          handleProductDriveErr(
            e.response.data.error.google_drive_link
              ? e.response.data.error.google_drive_link[0]
              : ""
          );
          handleLoader(false);
        });
    } else {
      handleLoader(false);
    }
  };
  
  const fetchProductImgs = () => {
    AxiosController.get(`api/${slug}/${lang}/panel-product/${productId}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        handleProductImgs(res?.data?.product_imgs);
        handleLoader(false);
      })
      .catch((e) => {
        handleLoader(false);
      });
  };
  const fetchProduct = () => {
    AxiosController.get(`api/${slug}/${lang}/panel-product/${productId}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        handleProductImgs(res.data.product_imgs);
        handleProductName(res.data.product.title);
        handleENProductName(res.data.product.en_title);
        handleProductDesc(res.data.product.description);
        handleENProductDesc(res.data.product.en_description);
        handleProductDrive(res.data.product.google_drive_link);
        handleProductCat(res.data.product.category_id);
        handleProductThumb(res.data.product.thumbnail);
        handleProductVariants(
          res.data.product_variants.map((variant, index) => {
            if (!variant.color) {
              delete variant.color;
            } else {
              instanceObject["color"] = "";
            }
            if (!variant.size) {
              delete variant.size;
            } else {
              instanceObject["size"] = "";
            }
            return { ...variant, isDeleted: false };
          })
        );

        handleLoader(false);
      })
      .catch((e) => {
        handleLoader(false);
      });
  };
  const fetchColors = () => {
    AxiosController.get(`apiv2/${slug}/${lang}/colors`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        setColors(res.data.map((v) => v.name));
      })
      .catch((e) => {});
  };
  const fetchSizes = () => {
    AxiosController.get(`apiv2/${slug}/${lang}/sizes`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        setSizes(res.data.map((v) => v.name));
      })
      .catch((e) => {});
  };
  useEffect(() => {
    fetchCategories(slug, lang, token, handleCategories, handleLoader);
    fetchProduct();
    fetchColors();
    fetchSizes();
  }, [slug, lang, token]);
  return (
    <div
      class="add-product-container d-flex flex-column gap-2 my-3 px-4 "
      style={{ flexGrow: 4, position: "relative" }}
    >
      <Loader active={loading} />
      <ProductImgsSlider
        productImgs={productImgs}
        productId={productId}
        handleLoader={handleLoader}
        fetchProductImgs={fetchProductImgs}
        productThumb={productThumb}
        fetchProduct={fetchProduct}
      />
      <div className="add-header" dir={box_head_dir}>
        <h2 className="mb-3">{_("panel.editProduct")}</h2>
        <p>{_("panel.addProductSlogan")}</p>
      </div>
      <div
        className="add-body1 d-flex flex-column flex-md-row gap-4 mb-4"
        dir={box_head_dir}
      >
        <div className="right-side d-flex flex-column">
          <div className="right-head">
            <h3>{_("panel.product_info")}</h3>
            <p>{_("panel.makesuredataisright")}</p>
          </div>
          <div className="mb-3">
            <label htmlFor="productName" className="form-label">
              {_("panel.productName")}
            </label>
            <input
              type="text"
              className="form-control py-2 px-3 egp"
              id="productName"
              aria-describedby="emailHelp"
              value={productName}
              onInput={(e) => {
                handleProductName(e.target.value);
              }}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="productName" className="form-label">
              {_("panel.enproductName")}
            </label>
            <input
              type="text"
              className="form-control py-2 px-3 egp"
              id="productName"
              aria-describedby="emailHelp"
              value={productENName}
              onInput={(e) => {
                handleENProductName(e.target.value);
              }}
            />
          </div>
          {productNameErr ? (
            <Alert variant={"danger"}>{productNameErr}</Alert>
          ) : (
            ""
          )}
          <div className="mb-3">
            <label htmlFor="category" className="form-label">
              {_("panel.category")}
            </label>
            <Form.Select
              aria-label="Default select example"
              value={productCat}
              onChange={(e) => {
                handleProductCat(e.target.value);
              }}
            >
              <option value={0}></option>
              {categories.length
                ? categories.map((cat) => {
                    return <option value={cat.id}>{cat.name}</option>;
                  })
                : ""}
            </Form.Select>
          </div>
          {productCatErr ? (
            <Alert variant={"danger"}>{productCatErr}</Alert>
          ) : (
            ""
          )}

          <div className="mb-3">
            <label htmlFor="driveLink" className="form-label">
              {_("panel.drivelink")}
            </label>
            <input
              type="text"
              className="form-control py-2 px-3 egp"
              id="driveLink"
              aria-describedby="emailHelp"
              value={productDrive}
              onInput={(e) => {
                handleProductDrive(e.target.value);
              }}
            />
          </div>
          {productDriveErr ? (
            <Alert variant={"danger"}>{productDriveErr}</Alert>
          ) : (
            ""
          )}
        </div>
        <div
          className="left-side d-flex flex-column align-items-center  text-center py-4 px-4 gap-2"
          style={{ height: "fit-content", overflow: "scroll", minWidth: "35%" }}
        >
          <div className="top py-3">
            <h3>{_("panel.productImgs")}</h3>
          </div>

          <FilePond
            acceptedFileTypes={acceptedFileTypes}
            files={files}
            ref={pond}
            onprocessfiles={() => {
              setTimeout(() => {
                setFiles([]);
                fetchProductImgs();
                handleLoader(false);
              }, 200);
            }}
            onupdatefiles={setFiles}
            allowMultiple={true}
            maxFiles={6}
            server={{
              url:
                BaseServerUrl +
                `/api/${slug}/${lang}/product-image/add-images/${productId}`,
              headers: {
                Authorization: `Bearer ${token}`,
              },
              process: {
                onload: (e) => {},
              },
            }}
            instantUpload={false}
            name="image"
            labelIdle={`${_(
              "panel.draglandpage"
            )} <span class="filepond--label-action">${_(
              "panel.browse"
            )}</span>`}
          />
          {productImgsErr ? (
            <Alert variant={"danger"}>{productImgsErr}</Alert>
          ) : (
            ""
          )}
        </div>
      </div>
      <div
        className="add-body1 d-flex flex-column-reverse flex-md-row gap-4"
        dir={box_head_dir}
      >
        <div className="right-side d-flex flex-column">
          {productDescErr ? (
            <Alert variant={"danger"}>{productDescErr}</Alert>
          ) : (
            ""
          )}
          <div className="mb-3">
            <label htmlFor="category" className="form-label">
              {_("panel.description")}
            </label>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <DescriptionEditor
                productDesc={productDesc}
                setProductDesc={handleProductDesc}
              />
            </Form.Group>
          </div>
          <div className="mb-3">
            <label htmlFor="category" className="form-label">
              {_("panel.endescription")}
            </label>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <DescriptionEditor
                productDesc={productENDesc}
                handleENProductDes={handleENProductDesc}
              />
            </Form.Group>
          </div>
          <label
            htmlFor="exampleInputEmail1"
            className="form-label d-flex justify-content-between"
          >
            {_("panel.productDetails")}
            <Button
              style={{
                backgroundColor: "rgb(243, 168, 55)",
                color: "white",
                border: "none",
              }}
              className="fs-5 px-3 py-1"
              onClick={() => {
                handleProductVariants([
                  ...productVariants,
                  { ...instanceObject, isDeleted: false } 
                ]);
              }}
            >
              {_("panel.newoffer")}
            </Button>
          </label>
          <div className="mb-3 d-flex flex-column">
            <VariantsProps
              Variants={productVariants}
              setVariants={handleProductVariants}
              stdObj={instanceObject}
              setStdObj={setInstanceObj}
            />
            {role != "admin"
              ? productVariants.map((variant, index) => {
                  return (
                    <div
                      className="offers d-flex gap-4 flex-md-row flex-column align-items-center"
                      style={{ width: "100%" }}
                    >
                      {role !== "fulfillment" ? (
                        <div className="flex-grow-1">
                          <Form.Label htmlFor="inputPassword5">
                            {_("panel.price")}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            id="inputPassword5"
                            aria-describedby="passwordHelpBlock"
                            value={variant.seller_commision}
                            onInput={(e) => {
                              handleProductVariants(() => {
                                return productVariants.map(
                                  (variant2, index2) => {
                                    if (index !== index2) {
                                      return variant2;
                                    }
                                    let offerInstance = variant2;
                                    offerInstance.seller_commision =
                                      e.target.value;
                                    return offerInstance;
                                  }
                                );
                              });
                            }}
                          />
                          {variant.seller_commisionErr ? (
                            <Alert variant={"danger"}>
                              {variant.seller_commisionErr}
                            </Alert>
                          ) : (
                            ""
                          )}
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="flex-grow-1">
                        <Form.Label htmlFor="inputPassword5">SKU</Form.Label>
                        <Form.Control
                          type="text"
                          id="inputPassword5"
                          aria-describedby="passwordHelpBlock"
                          value={variant.sku}
                          disabled={variant.id}
                          onInput={(e) => {
                            handleProductVariants(() => {
                              return productVariants.map((variant2, index2) => {
                                if (index !== index2) {
                                  return variant2;
                                }
                                let offerInstance = Array.from(variant2);
                                offerInstance.sku = e.target.value;
                                return offerInstance;
                              });
                            });
                          }}
                        />
                        {variant.seller_commisionErr ? (
                          <Alert variant={"danger"}>
                            {variant.seller_commisionErr}
                          </Alert>
                        ) : (
                          ""
                        )}
                      </div>
                      {(employee_type === "operation employee" ||
                        employee_type === "products employee") && (
                        <div>
                          <div className="flex-grow-1">
                            <Form.Label htmlFor="inputPassword5">
                              {_("panel.Approved")}
                            </Form.Label>
                            <Form.Select
                              aria-label="Default select example"
                              value={variant.is_approved}
                              onChange={(e) => {
                                handleProductVariants(() => {
                                  return productVariants.map(
                                    (variant2, index2) => {
                                      if (index !== index2) {
                                        return variant2;
                                      }
                                      let offerInstance = variant2;
                                      offerInstance.is_approved =
                                        e.target.value;
                                      return offerInstance;
                                    }
                                  );
                                });
                              }}
                            >
                              <option value={""}></option>
                              <option value={1}>{_("panel.Approved")}</option>
                              <option value={0}>{_("panel.Rejected")}</option>
                            </Form.Select>
                            {variant.is_approvedErr ? (
                              <Alert variant={"danger"}>
                                {variant.is_approvedErr}
                              </Alert>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      )}

                      {/* {Object.keys(variant).includes("color") ? (
                        <div className="flex-grow-1">
                          <Form.Label htmlFor="inputPassword5">
                            {_("panel.color")}
                          </Form.Label>
                          <Form.Control
                            type="color"
                            style={{ width: "100%" }}
                            id="exampleColorInput"
                            title="Choose your color"
                            value={variant.color}
                            onInput={(e) => {
                              handleProductVariants(() => {
                                return productVariants.map(
                                  (variant2, index2) => {
                                    if (index !== index2) {
                                      return variant2;
                                    }
                                    let offerInstance = variant2;
                                    offerInstance.color = e.target.value;
                                    return offerInstance;
                                  }
                                );
                              });
                            }}
                          />
                        </div>
                      ) : (
                        ""
                      )} */}
                      {Object.keys(variant).includes("color") ? (
                        <div className="flex-grow-1">
                          <Form.Label htmlFor="inputPassword5">
                            {_("panel.color")}
                          </Form.Label>
                          <Form.Select
                            aria-label="Default select example"
                            value={variant.color}
                            onChange={(e) => {
                              handleProductVariants(() => {
                                return productVariants.map(
                                  (variant2, index2) => {
                                    if (index !== index2) {
                                      return variant2;
                                    }
                                    let offerInstance = variant2;
                                    offerInstance.color = e.target.value;
                                    return offerInstance;
                                  }
                                );
                              });
                            }}
                          >
                            <option value={""}></option>
                            {Colors.length
                              ? Colors.map((Color) => {
                                  return <option value={Color}>{Color}</option>;
                                })
                              : ""}
                          </Form.Select>
                        </div>
                      ) : (
                        ""
                      )}
                      {Object.keys(variant).includes("size") ? (
                        <div className="flex-grow-1">
                          <Form.Label htmlFor="inputPassword5">
                            {_("panel.size")}
                          </Form.Label>
                          <Form.Select
                            aria-label="Default select example"
                            value={variant.size}
                            onChange={(e) => {
                              handleProductVariants(() => {
                                return productVariants.map(
                                  (variant2, index2) => {
                                    if (index !== index2) {
                                      return variant2;
                                    }
                                    let offerInstance = variant2;
                                    offerInstance.size = e.target.value;
                                    return offerInstance;
                                  }
                                );
                              });
                            }}
                          >
                            <option value={""}></option>
                            {Sizes.length
                              ? Sizes.map((Size) => {
                                  return <option value={Size}>{Size}</option>;
                                })
                              : ""}
                          </Form.Select>
                        </div>
                      ) : (
                        ""
                      )}
                      {/* <div className="flex-grow-1">
                        <Form.Label htmlFor="inputPassword5">
                          {_("panel.quantity")}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          id="inputPassword5"
                          aria-describedby="passwordHelpBlock"
                          value={variant.quantity}
                          onInput={(e) => {
                            handleProductVariants(() => {
                              return productVariants.map((variant2, index2) => {
                                if (index !== index2) {
                                  return variant2;
                                }
                                let offerInstance = variant2;
                                offerInstance.quantity = e.target.value;
                                return offerInstance;
                              });
                            });
                          }}
                        />
                        {variant.quantityErr ? (
                          <Alert variant={"danger"}>
                            {variant.quantityErr}
                          </Alert>
                        ) : (
                          ""
                        )}
                      </div> */}
                      {/* <div className="flex-grow-1">
                        <Form.Label htmlFor="inputPassword5">
                          {_("panel.warehouse")}
                        </Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          onChange={(e) => {
                            handleProductVariants(() => {
                              return productVariants.map((variant2, index2) => {
                                if (index !== index2) {
                                  return variant2;
                                }
                                let offerInstance = variant2;
                                offerInstance.warehouse = e.target.value;
                                return offerInstance;
                              });
                            });
                          }}
                        >
                          <option value={-1}></option>
                          <option value={0}>Ecombo</option>
                          <option value={1}>{_("panel.yourStore")}</option>
                        </Form.Select>
                        {variant.warehouseErr ? (
                          <Alert variant={"danger"}>
                            {variant.warehouseErr}
                          </Alert>
                        ) : (
                          ""
                        )}
                      </div> */}
                      <div
                        className="flex-grow-1 mt-4"
                        onClick={() => {
                          handleProductVariants(() => {
                            return productVariants.filter((value, index2) => {
                              return index != index2;
                            });
                          });
                        }}
                      >
                        <IconsGen icon_name={"delete"} />
                      </div>
                    </div>
                  );
                })
              : ""}
            {role == "admin"
              ? productVariants
                  .filter((variant) => variant.isDeleted === false)
                  .map((variant, index) => {
                    return (
                      <div
                      key={index +1}
                        className="offers d-flex gap-4 flex-md-row flex-column align-items-center"
                        style={{ width: "100%" }}
                      >
                        <div className="flex-grow-1">
                          <Form.Label htmlFor="inputPassword5">
                            {_("panel.Sellerprice")}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            id="inputPassword5"
                            aria-describedby="passwordHelpBlock"
                            value={variant.seller_commision}
                            onInput={(e) => {
                              handleProductVariants(() => {
                                return productVariants.map(
                                  (variant2, index2) => {
                                    if (index !== index2) {
                                      return variant2;
                                    }
                                    let offerInstance = variant2;
                                    offerInstance.seller_commision =
                                      e.target.value;
                                    return offerInstance;
                                  }
                                );
                              });
                            }}
                          />
                          {variant.seller_commisionErr ? (
                            <Alert variant={"danger"}>
                              {variant.seller_commisionErr}
                            </Alert>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="flex-grow-1">
                          <Form.Label htmlFor="inputPassword5">
                            {_("panel.Sysprice")}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            id="inputPassword5"
                            aria-describedby="passwordHelpBlock"
                            value={variant.sys_commision}
                            onInput={(e) => {
                              handleProductVariants(() => {
                                return productVariants.map(
                                  (variant2, index2) => {
                                    if (index !== index2) {
                                      return variant2;
                                    }
                                    let offerInstance = variant2;
                                    offerInstance.sys_commision =
                                      e.target.value;
                                    return offerInstance;
                                  }
                                );
                              });
                            }}
                          />
                          {variant.sys_commisionErr ? (
                            <Alert variant={"danger"}>
                              {variant.sys_commisionErr}
                            </Alert>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="flex-grow-1">
                          <Form.Label htmlFor="inputPassword5">
                            {_("panel.Approved")}
                          </Form.Label>
                          <Form.Select
                            aria-label="Default select example"
                            value={variant.is_approved}
                            onChange={(e) => {
                              handleProductVariants(() => {
                                return productVariants.map(
                                  (variant2, index2) => {
                                    if (index !== index2) {
                                      return variant2;
                                    }
                                    let offerInstance = variant2;
                                    offerInstance.is_approved = e.target.value;
                                    return offerInstance;
                                  }
                                );
                              });
                            }}
                          >
                            <option value={""}></option>
                            <option value={1}>{_("panel.Approved")}</option>
                            <option value={0}>{_("panel.Rejected")}</option>
                          </Form.Select>
                          {variant.is_approvedErr ? (
                            <Alert variant={"danger"}>
                              {variant.is_approvedErr}
                            </Alert>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="flex-grow-1">
                          <Form.Label htmlFor="inputPassword5">SKU</Form.Label>
                          <Form.Control
                            type="text"
                            disabled={true}
                            id="inputPassword5"
                            aria-describedby="passwordHelpBlock"
                            value={variant.sku}
                          />
                        </div>

                        {Object.keys(variant).includes("color") ? (
                          <div className="flex-grow-1">
                            <Form.Label htmlFor="inputPassword5">
                              {_("panel.color")}
                            </Form.Label>
                            <Form.Select
                              aria-label="Default select example"
                              value={variant.color}
                              onChange={(e) => {
                                handleProductVariants(() => {
                                  return productVariants.map(
                                    (variant2, index2) => {
                                      if (index !== index2) {
                                        return variant2;
                                      }
                                      let offerInstance = variant2;
                                      offerInstance.color = e.target.value;
                                      return offerInstance;
                                    }
                                  );
                                });
                              }}
                            >
                              <option value={""}></option>
                              {Colors.length
                                ? Colors.map((Color) => {
                                    return (
                                      <option value={Color}>{Color}</option>
                                    );
                                  })
                                : ""}
                            </Form.Select>
                          </div>
                        ) : (
                          ""
                        )}

                        {Object.keys(variant).includes("size") ? (
                          <div className="flex-grow-1">
                            <Form.Label htmlFor="inputPassword5">
                              {_("panel.size")}
                            </Form.Label>
                            <Form.Select
                              aria-label="Default select example"
                              value={variant.size}
                              onChange={(e) => {
                                handleProductVariants(() => {
                                  return productVariants.map(
                                    (variant2, index2) => {
                                      if (index !== index2) {
                                        return variant2;
                                      }
                                      let offerInstance = variant2;
                                      offerInstance.size = e.target.value;
                                      return offerInstance;
                                    }
                                  );
                                });
                              }}
                            >
                              <option value={""}></option>
                              {Sizes.length
                                ? Sizes.map((Size) => {
                                    return <option value={Size}>{Size}</option>;
                                  })
                                : ""}
                            </Form.Select>
                          </div>
                        ) : (
                          ""
                        )}

                        <div
                          className="flex-grow-1 mt-4"
                          onClick={() => {
                            const variantToDelete = productVariants[index];
                        
                            if (variantToDelete.id) {
                              // Add the variant ID to variantsToDelete list
                              setVariantsToDelete(prev => [...prev, variantToDelete.id]);
                            }
                        
                            // Remove the variant from the displayed list immediately
                            handleProductVariants(prevVariants =>
                              prevVariants.filter((_, idx) => idx !== index)
                            );
                          }}
                        >
                          <IconsGen icon_name={"delete"} />
                        </div>
                      </div>
                    );
                  })
              : ""}
          </div>
          <div className="mb-3">
            <Button
              style={{
                backgroundColor: "rgba(89, 198, 201, 1)",
                color: "white",
                border: "none",
                width: "100%",
              }}
              className="fs-5"
              onClick={() => {
                handleProductNameErr("");
                handleProductCatErr("");
                handleProductDescErr("");
                handleProductDriveErr("");
                create_product();
              }}
            >
              {_("panel.save")}
            </Button>
          </div>
          {success ? <Alert variant={"success"}>{success}</Alert> : ""}
        </div>
      </div>
    </div>
  );
};

export default ProductData;
