import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { useTranslation } from "react-i18next"; // Import the useTranslation hook
import warehouseImg from "../../imgs/static_home_images/warehouse.png";
import deliveryBoxImg from "../../imgs/static_home_images/delivery-box.png";
import marketingAndSales from "../../imgs/static_home_images/marketing-and-sales.png";
import moneyImg from "../../imgs/static_home_images/money.png";
import { useParams } from "react-router-dom";

export default function SupplierSolution() {
  const { lang } = useParams();
  const [_] = useTranslation("global");

  const data = [
    {
      img: warehouseImg,
      title: _("static_home.supplierSolution.inventoryStorage.title"),
      text: _("static_home.supplierSolution.inventoryStorage.text"),
    },    {
      img: marketingAndSales,
      title: _("static_home.supplierSolution.marketingAndSales.title"),
      text: _("static_home.supplierSolution.marketingAndSales.text"),
    },
    {
      img: deliveryBoxImg,
      title: _("static_home.supplierSolution.packagingShipping.title"),
      text: _("static_home.supplierSolution.packagingShipping.text"),
    },

    {
      img: moneyImg,
      title: _("static_home.supplierSolution.collection.title"),
      text: _("static_home.supplierSolution.collection.text"),
    },
  ];

  return (
    <section
      className="py-5 overflow-hidden"
      dir={lang === "en" ? "ltr" : "rtl"}
      id="supplierSolution"
    >
      <div className="text-center mb-5" data-aos="fade-up" data-aos-delay={100}>
        <h2 className="ecombo-primary fw-bold">
          {_("static_home.supplierSolution.title")}
        </h2>
        <p>{_("static_home.supplierSolution.description")}</p>
      </div>

      <Container className="mt-5">
        <Row className="justify-content-center">
          {data.map((item, idx) => {
            const middle = idx === 1;
            return (
              <Col
                key={idx + 1}
                xs={12}
                sm={6}
                md={4}
                className={`d-flex justify-content-center mb-4  ${
                  middle ? "" : "mt-5"
                }`}
                data-aos="fade-up"
                data-aos-delay={idx * 200}
              >
                <Card
                  style={{ backgroundColor: "#FEF5E9" }}
                  className="  p-4 rounded-lg text-center border-0"
                >
                  <img
                    alt={item.title}
                    src={item.img}
                    width={100}
                    height={100}
                    className="mx-auto mb-3"
                    data-aos="fade-in"
                    data-aos-delay={idx * 200 + 100}
                  />
                  <h2 className="text-3xl font-normal ecombo-primary">
                    {item.title}
                  </h2>
                  <p className="max-w-[280px] mx-auto">{item.text}</p>
                </Card>
              </Col>
            );
          })}
        </Row>
      </Container>
    </section>
  );
}
