import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Container, Row, Col, Card, ProgressBar } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import AxiosController from "../../components/authentication/AxiosController";
import start from "../../imgs/start.webp"
import { UserContext } from "../../components/contexts/UserContext";
import Loader from "../../components/loader/Loader";
const Rewards = () => {
  const { slug, lang } = useParams();
  const [_] = useTranslation("global");
  const { userData } = useContext(UserContext);
  const token = localStorage.getItem("token");
  const [data, setData] = useState([]);
  const progress=userData?.progress;
  
  const [loading, handleLoader] = useState(false);
 
  const fetchLevels = () => {
    AxiosController.get(`api/${slug}/${lang}/level/get-levels`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        handleLoader(false);
        // Dummy level data
        const dummyLevel = {
          level: " البداية", // Set level name
          target: 0,  // Set target for the dummy level
          image_url:start, // Placeholder image
        };

        // Add dummy level to the fetched levels
        const updatedData = [dummyLevel, ...res.data];

        setData(updatedData);
      })
      .catch((e) => {
        handleLoader(false);
      });
  };
 
  useEffect(() => {
    fetchLevels(); 
  }, [progress]);

 

  const calculateProgress = (currentProgress, levelStart, levelEnd) => {
    if (currentProgress <= levelStart) {
      return 0;
    } else if (currentProgress >= levelEnd) {
      return 100;
    } else {
      return ((currentProgress - levelStart) / (levelEnd - levelStart)) * 100;
    }
  };

  return (
    <Container
      dir={lang === "en" ? "ltr" : "rtl"}
      fluid
      className="text-center p-5 mt-5"
    >
    
      <Row className="mb-4">
        <Col>
          <h4 className="fw-bolder">{_("panel.ecomboTrip")}</h4>
          {/* <p>(01 Sep, 2024 – 30 Sep, 2024)</p> */}
     
        </Col>
      </Row>

      <Row className="justify-content-center align-items-center mb-4 m-auto">
        {data && data.length > 0 && data.map((rank, index) => { 

          const levelStart =   data[index]?.target;
          const levelEnd =data[index +1 ]?.target ;
          

          // Calculate the percentage progress for the current level
          const percentageProgress = calculateProgress(progress, levelStart, levelEnd);
  

          return (
            <div key={index} className="col-md-3 col-6 d-lg-flex d-block">
              <div className="py-2">
                
                <p className="fw-bold">{rank.level}</p>
                <div
                  className="rounded-circle p-4 my-4"
                  style={{ backgroundColor: "var(--primary-color)" }}
                >
                  <img src={rank.image_url} alt="img"  className="w-100" height={80} />
                </div>
                <Card.Text>{_('panel.confirmedOrder')} {rank.target}</Card.Text>
              </div>
              {index < data.length - 1 && (
                <div
                  className="rounded"
                  style={{
                    width: "50%",
                    height: "15px",
                    backgroundColor: "var(--secondary-color)",
                    margin: "auto",
                  }}
                >
                  <ProgressBar
                    animated
                    variant="warning"
                    now={percentageProgress}
                    label={`${Math.round(percentageProgress)}%`}
                  />
                </div>
              )}
            </div>
          );
        })}
      </Row>
    </Container>
  );
};

export default Rewards;
