import React from "react";
import Map from "./Map";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

export default function Where() {
  const [_] = useTranslation("global");
  const { lang } = useParams();

  return (
    <>
      <Row id="where" dir={lang === "en" ? "ltr" : "rtl"} className="text-center mb-5 py-5">
        <Col>
          <h2 className="ecombo-primary fw-bold">
            {_("static_home.where.title")}
          </h2>
          <p className="lead text-muted">
            {_("static_home.where.description")}
          </p>
        </Col>
      </Row>
      <div>
        <Col>
          <Map />
        </Col>
      </div>
    </>
  );
}
