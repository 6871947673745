import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Modal, Button, Alert } from "react-bootstrap";
import AxiosController from "../authentication/AxiosController";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { notifyError, notifySuccess } from "../toastify/toastify";

const CreateAndUpdateProductDiscounts = ({
  method,
  selectedProductId,
  productData,
  show,
  handleModalClose,
  handleModalSuccess,
}) => {
  const { slug, lang, productId } = useParams();
  const token = localStorage.getItem("token"); 
  const [loading, setLoading] = useState(false);
  const { t: _ } = useTranslation("global");
  const box_head_dir = lang === "en" ? "ltr" : "rtl";

  // Validation schema using Yup
  const validationSchema = Yup.object({
    product_id: Yup.string().required(_("panel.product_idRequired")),
    sys_modified_commission: Yup.number()
      .required(_("panel.commissionRequired"))
      .min(1, _("panel.commissionMin"))
      .typeError(_("panel.commissionNumber")),
      seller_modified_commission: Yup.number()
      .required(_("panel.seller_modified_commission"))
      .min(1, _("panel.seller_modified_commissionMin"))
      .typeError(_("panel.seller_modified_commissionNumber")),
    quantity: Yup.number()
      .required(_("panel.quantityRequired"))
      .min(1, _("panel.quantityMin"))
      .typeError(_("panel.quantityNumber")),
  });

  // Initial form values
  const initialValues = {
    product_id: productId,
    sys_modified_commission: "" || productData?.sys_modified_commission,
    seller_modified_commission: "" || productData?.seller_modified_commission,
    quantity: "" || productData?.quantity,
  };

  // Form submission handler
  const handleSubmit = (values, { setSubmitting, resetForm }) => {
    const apiUrl =
      method === "edit"
        ? `/apiv2/${slug}/${lang}/product-discounts/${selectedProductId}`
        : `/apiv2/${slug}/${lang}/product-discounts`;

    const requestMethod =
      method === "edit" ? AxiosController.put : AxiosController.post;

    requestMethod(apiUrl, values, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => { 
        resetForm();
        if (method === "edit") {
          notifySuccess(_("panel.updateSuccessMsg"));
        } else {
          notifySuccess(_("panel.successMsg"));
        }
        if (handleModalSuccess) handleModalSuccess();
        handleModalClose();
      })
      .catch((error) => {
        setLoading(false);
        if (error.response && typeof error.response.data === "object") {
          const errorMessages = Object.entries(error.response.data)
            .map(
              ([key, value]) =>
                `${key}: ${Array.isArray(value) ? value.join(", ") : value}`
            )
            .join(", ");

          notifyError(errorMessages);
        } else {
          notifyError(
            Array.isArray(error.response.data)
              ? error.response.data.join(", ")
              : error.response.data
          );
        }
      })
      .finally(() => setSubmitting(false));
  };

  return (
    <Modal show={show} onHide={handleModalClose} centered>
      <Modal.Header closeButton>
        <Modal.Title dir={box_head_dir}>
          {method === "edit" ? _("panel.edit") : _("panel.add")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form>
              <div className="mb-3">
                <label htmlFor="product_id" className="form-label">
                  {_("panel.product_id")}
                </label>
                <Field
                  type="number"
                  name="product_id"
                  disabled
                  className="form-control"
                />
                <ErrorMessage
                  name="product_id"
                  component="div"
                  className="text-danger"
                />
              </div>

              <div className="mb-3">
                <label htmlFor="sys_modified_commission" className="form-label">
                  {_("panel.sys_commission")}
                </label>
                <Field
                  type="number"
                  name="sys_modified_commission"
                  className="form-control"
                />
                <ErrorMessage
                  name="sys_modified_commission"
                  component="div"
                  className="text-danger"
                />
              </div>
              <div className="mb-3">
                <label htmlFor="seller_modified_commission" className="form-label">
                  {_("panel.seller_commission")}
                </label>
                <Field
                  type="number"
                  name="seller_modified_commission"
                  className="form-control"
                />
                <ErrorMessage
                  name="seller_modified_commission"
                  component="div"
                  className="text-danger"
                />
              </div>

              <div className="mb-3">
                <label htmlFor="quantity" className="form-label">
                  {_("panel.quantity")}
                </label>
                <Field type="number" name="quantity" className="form-control" />
                <ErrorMessage
                  name="quantity"
                  component="div"
                  className="text-danger"
                />
              </div>

              <Button
                type="submit"
                disabled={isSubmitting}
                style={{
                  backgroundColor: "rgba(89, 198, 201, 1)",
                  color: "white",
                  border: "none",
                  width: "100%",
                }}
              >
                {isSubmitting
                  ? "جاري الحفظ ...."
                  : method === "edit"
                  ? _("panel.update")
                  : _("panel.save")}
              </Button> 
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default CreateAndUpdateProductDiscounts;
