import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, Link, useParams, useNavigation } from "react-router-dom";
import IconsGen from "../../icons_generator/IconsGen";
import AxiosController from "../../authentication/AxiosController";
import { notifyError, notifySuccess } from "../../toastify/toastify";
import ConfirmModal from "../../modals/ConfirmModal";
import FunnelServerUrl from "../../authentication/FunnelServerUrl";
const MyLandpagesTableRow = ({ landingData, fetchLanding }) => {
  const { lang, slug } = useParams();
  const navigate = useNavigate();
  const [_] = useTranslation("global");
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [landingId, setLandingId] = useState(null);
  const [uid, setUid] = useState(null);
  const [loading, handleLoader] = useState(false);
  const token = localStorage.getItem("token");
  const [pixels, setPixels] = useState([]);

  const handleVisibilityChange = (id) => {
    handleLoader(true);
    AxiosController.put(
      `apiv2/${slug}/${lang}/builder/landpage-toggle-visibility/${id}`,
      {},
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
      .then(() => {
        handleLoader(false);
        notifySuccess(_("panel.updateSuccessMsg"));
      })
      .catch(() => {
        handleLoader(false);
      });
  };

  const handleConfirmDelete = () => {
    handleLoader(true);
    AxiosController.post(
      `apiv2/${slug}/${lang}/builder/my-landpages/delete`,
      {
        id: landingId,
        uid: uid,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
      .then(() => {
        fetchLanding();
        notifySuccess(_("panel.delete_successful"));
        handleLoader(false);
      })
      .catch((e) => {
        notifyError(e.message);
        handleLoader(false);
      })
      .finally(() => handleCloseModal());
  };

  const handleCloseModal = () => {
    setShowConfirmModal(false);
  };

  const handleShowModal = (id, uid) => {
    setShowConfirmModal(true);
    setLandingId(id);
    setUid(uid);
  };

  const fetchPixels = () => {
    handleLoader(true);

    AxiosController.get(`apiv2/${slug}/${lang}/builder/users-pixels`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        setPixels(res.data);
        handleLoader(false);
      })
      .catch((e) => {
        console.error("Error fetching pixels:", e);
        handleLoader(false);
      });
  };

  const requestSession = (uid) => {
    handleLoader(true);
    AxiosController.get(
      `apiv2/${slug}/${lang}/builder/my-landpage-edit-session?uid=${uid}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
      .then((res) => {
        handleLoader(false);
        window.open(
          `${FunnelServerUrl}?uid=${uid}&session=${res.data}`,
          "_blank"
        );
      })
      .catch((e) => {
        handleLoader(false);
      });
  };
  useEffect(() => {
    fetchPixels();
  }, []);

  return (
    <div
      className={`table-responsive my-3 d-flex ${
        lang === "en" ? "flex-row" : "flex-row-reversed"
      } align-items-center`}
    >
      <ConfirmModal
        showModal={showConfirmModal}
        setShowModal={setShowConfirmModal}
        action={handleConfirmDelete}
        modalBody={`deleteSure`}
        modalHeader="deleteProduct"
        confirmButton="confirm"
      />
      <table className="table table-borderless">
        <thead>
          <tr>
            <th>{_("panel.theme_name")}</th>
            <th>{_("panel.store_visibility")}</th>
            <th>{_("panel.product")}</th>
            <th>{_("panel.build")}</th>
            <th>{_("panel.offers")}</th>
            <th>{_("panel.delete")}</th>
          </tr>
        </thead>
        <tbody>
          {landingData?.data?.map((landing) => (
            <tr key={landing?.id}>
              <td>
                #{landing?.theme_id} <br /> {landing?.theme.name} <br />{" "}
                <a
                  className="ecombo-link-secondary"
                  target="_blank"
                  href={`https://${landing?.store?.sub_domain}.tejarty.store/product/${landing?.uid}`}
                >
                  {_("panel.visit")}
                </a>{" "}
              </td>
              <td>
                <select
                  className="form-select text-center"
                  value={landing?.landing_visibility}
                  onChange={() => handleVisibilityChange(landing?.id)}
                  disabled={loading}
                >
                  <option value="1">
                    {landing?.store_visibility === 0
                      ? _("panel.no")
                      : _("panel.yes")}
                  </option>
                  <option value="0">
                    {landing?.store_visibility === 1
                      ? _("panel.no")
                      : _("panel.yes")}
                  </option>
                </select>
              </td>
              <td>
                #{landing?.product.id} <br /> {landing?.product.title} <br />{" "}
                <img
                  src={landing.product.thumbnail}
                  alt="product"
                  width={"70px"}
                  height={"70px"}
                  className="rounded"
                />
              </td>

              <td onClick={() => requestSession(landing?.uid)}>
                <IconsGen icon_name={"construction"} />
              </td>
              <td
                onClick={() =>
                  navigate(
                    `/${slug}/${lang}/panel/my-shop/offers/${landing.id}`
                  )
                }
              >
                <IconsGen icon_name={"enter"} style={{ cursor: "pointer" }} />
              </td>
              <td onClick={() => handleShowModal(landing?.id, landing?.uid)}>
                <IconsGen icon_name={"delete"} style={{ cursor: "pointer" }} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default MyLandpagesTableRow;
