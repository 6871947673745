import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next"; // Importing useTranslation hook
import whyImg from "../../imgs/static_home_images/why.png";
import { useParams } from "react-router-dom";

export default function Why() {
  const [_] = useTranslation("global"); // Using the 'global' namespace for translations
  const { lang } = useParams();
  return (
    <section
      dir={lang === "en" ? "ltr" : "rtl"}
      className="py-5"
      id="why"
      data-aos="fade-up"
    >
      <Container>
        {" "}
        <div
          className="text-center mb-4"
          data-aos="fade-up"
          data-aos-delay="100"
        >
          <h2 className="ecombo-primary fw-bold">
            {_("static_home.why.title")}
          </h2>
          <p className="mt-3 mx-auto" style={{ maxWidth: "800px" }}>
            {_("static_home.why.description")}
          </p>
          
        </div>
      </Container>

      <Container className="bg-warning-subtle p-4 rounded">
        <Row className="align-items-center py-4">
          <Col
            md={6}
            className="text-center text-md-start"
            data-aos="fade-right"
            data-aos-delay="300"
          >
            <p className="fs-5 text-muted">{_("static_home.why.text")}</p>
          </Col>
          <Col
            md={6}
            className="text-center"
            data-aos="fade-left"
            data-aos-delay="300"
          >
            <img
              src={whyImg}
              alt={_("static_home.why.imageAlt")}
              className="img-fluid rounded"
              data-aos="zoom-in"
              data-aos-delay="400"
            />
          </Col>
        </Row>
      </Container>
    </section>
  );
}
