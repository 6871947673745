import React, { useState } from "react";
import { Card, Row, Col, Image } from "react-bootstrap";
import IconsGen from "../icons_generator/IconsGen";
import "./index.css";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import AxiosController from "../authentication/AxiosController";

const CartItem = ({
  price,
  quantity,
  title,
  altName,
  thumbnail,
  cartId,
  productId,
  handleCart,
  cart,
  is_product_discount,
  offer_id
}) => {
  const { slug, lang } = useParams();
  const [_] = useTranslation("global");
  const [cartQuantity, handleQuantity] = useState(quantity);
  const token = localStorage.getItem("token");

  const updateCart = (newQuantity) => {
    AxiosController.put(
      `api/${slug}/${lang}/update-cart/`,
      { cartId, productId, newQuantity, price },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
      .then((res) => {})
      .catch((e) => {});
  };

  function removeCartItem() {
    AxiosController.put(
      `api/${slug}/${lang}/delete-cart/`,
      { cartId, productId },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
      .then((res) => { 
        handleCart(
          cart.filter((item) => {
            return item.id !== cartId;
          })
        );
      })
      .catch((e) => {});
  }

  return (
    <Card
      className="d-flex flex-column flex-sm-row-reverse gap-2 px-2 py-3 align-items-center"
      style={{
        border: "1px solid rgba(239, 237, 237, 1)",
        borderRadius: "8px",
      }}
      dir={lang === "en" ? "rtl" : "ltr"}
    >
      <div className="img-container">
        <Image src={thumbnail} width="100px" alt="" />
      </div>
      <div className="data-holder flex-grow-1 gap-1">
        <Row className="left-container d-flex flex-column gap-2">
          <Col
            className="top d-flex justify-content-between flex-row-reverse align-items-center"
            style={{ color: "rgba(23, 43, 77, 1)" }}
          >
            <h3 style={{ fontSize: "1.1rem" }}>{title}</h3>
            <div className="px-2" onClick={removeCartItem}>
              <IconsGen icon_name={"deleteCart"} />
            </div>
          </Col>
          <Col className="bottom d-flex flex-row-reverse gap-2">
            <div
              className="pricing flex-grow-1 text-center"
              style={{ color: "rgba(89, 198, 201, 1)", fontSize: "19px" }}
            >
              <h4 className="heading">{_("home.costprice")}</h4>
              <p className="under">
                {price} {altName}
              </p>
            </div>
            <div className="pricing flex-grow-1 text-center">
              <h4 className="heading">{_("home.quantity")}</h4>
              <div className="quantity-container d-flex justify-content-center align-items-center gap-2">
              { offer_id ||is_product_discount ? "" :  (   <div
                  onClick={() => {
                    updateCart(cartQuantity + 1);
                    handleQuantity(cartQuantity + 1);
                  }}
                >
                  <IconsGen icon_name={"addCircle"} />
                </div>)}
                <p style={{ margin: "0", fontSize: "1.1rem" }}>
                  {cartQuantity}
                </p>
                { offer_id ||is_product_discount ? "" :  (   <div
                  onClick={() => {
                    updateCart(cartQuantity - 1 > 0 ? cartQuantity - 1 : 1);
                    handleQuantity(cartQuantity - 1 > 0 ? cartQuantity - 1 : 1);
                  }}
                >
                  <IconsGen icon_name={"minusCircle"} />
                </div>)}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </Card>
  );
};

export default CartItem;
