import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, Modal } from "react-bootstrap";
import AxiosController from "../authentication/AxiosController";
import { notifyError, notifySuccess } from "../toastify/toastify";

const CurrencyAddAndUpdate = ({ show,  setShow,  showImageEdit,  setShowImageEdit,  id,  update,  data,  fetchCurrency,}) => {
  const { slug, lang } = useParams();
  const token = localStorage.getItem("token");
  const { t } = useTranslation("global");
  const [loading, handleLoader] = useState(false);
  const [name, setName] = useState('');
  const [slug_input, setSlug_input] = useState('');
  const [alt_name, setAlt_name] = useState('');
  const [country, setCountry] = useState("");
  const [image, setImage] = useState(null);

  useEffect(() => {
    if (data) {
      setName(data?.name);
      setAlt_name(data?.alt_name)
      setSlug_input(data?.slug);
      setCountry(data?.country);
      setImage(data?.image);
    }
  }, [data]);
  const createOrUpdateCurrency = () => {
    handleLoader(true);
    const url = update
      ? `api/${slug}/${lang}/currencies/update-currency/${id}`
      : `api/${slug}/${lang}/currencies/add-currency`;
    const method = update ? "put" : "post";
 
    AxiosController[method](
      url,
      {
        name,
        alt_name,
        country,
        slug:slug_input,
        ...(!update && { image }),
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          ...(!update && { "Content-Type": "multipart/form-data" }),
        },
      }
    )
      .then((res) => {
        notifySuccess(t("panel.updateSuccessMsg"))
        fetchCurrency();
        handleLoader(false);
        handleClose();
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.error) {
          const errors = e.response.data.error;
          Object.entries(errors).forEach(([key, value]) => {
            if (Array.isArray(value)) {
              value.forEach((err) => notifyError(`${key}: ${err}`));
            } else {
              notifyError(`${key}: ${value}`);
            }
          });
        } else {
          notifyError("An unexpected error occurred.");
        }
        handleLoader(false);
      });
  };

  const UpdateImageCurrency = () => {
    handleLoader(true);
    AxiosController.post(
      `api/${slug}/${lang}/currencies/update-currency-image/${id}`,
      {
        image,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      }
    )
      .then((res) => {
        notifySuccess(t("panel.updateSuccessMsg"))
        handleCloseImage();
        fetchCurrency();
        handleLoader(false);
      })
      .catch((e) => {
        handleLoader(false);
        if (e.response && e.response.data && e.response.data.error) {
          const errors = e.response.data.error;
          Object.entries(errors).forEach(([key, value]) => {
            if (Array.isArray(value)) {
              value.forEach((err) => notifyError(`${key}: ${err}`));
            } else {
              notifyError(`${key}: ${value}`);
            }
          });
        } else {
          notifyError("An unexpected error occurred.");
        }
      });
  };

  const handleClose = () => {
    setShow(false);
  };
  const handleCloseImage = () => {
    setShowImageEdit(false);
    setImage(data?.image);
  };

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton className="border-0">
          <Modal.Title className="flex-grow-1">
            {update ? t("panel.update") : t("panel.add")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!update && (
            <div className="mb-3">
              <label className="form-label">{t("panel.image")}</label>
              <input
                type="file"
                className="form-control py-2"
                onChange={(e) => setImage(e.target.files[0])}
              />
              {image && (
                <div className="mt-3">
                  <img
                    src={URL.createObjectURL(image)}
                    alt="Selected"
                    className="img-thumbnail"
                    style={{ maxWidth: "200px", maxHeight: "200px" }}
                  />
                </div>
              )}
            </div>
          )}
          {labelInput(t("panel.currency_name"), name , setName)}
          {labelInput(t("panel.slug"), slug_input , setSlug_input)}
          {labelInput(t("panel.alt_name"), alt_name , setAlt_name)}
          {labelInput(t("panel.country"), country , setCountry)}
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button disabled={loading} variant="primary" onClick={createOrUpdateCurrency}>
            {t("panel.save")}
          </Button>
          <Button variant="danger" onClick={handleClose}>
            {t("panel.cancel")}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showImageEdit} onHide={handleCloseImage}>
        <Modal.Header closeButton className="border-0">
          <Modal.Title className="flex-grow-1">
            {update ? t("panel.updateCurrency") : t("panel.addCurrency")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-3">
            <label className="form-label">{t("panel.image")}</label>
            <input
              type="file"
              className="form-control py-2"
              onChange={(e) => setImage(e.target.files[0])}
            />
            <div className="mt-3">
              {image instanceof Blob || image instanceof File ? (
                <img
                  src={URL.createObjectURL(image)}
                  alt="Selected"
                  className="img-thumbnail"
                  style={{ maxWidth: "200px", maxHeight: "200px" }}
                />
              ) : (
                <img
                  src={image}
                  alt="Selected"
                  className="img-thumbnail"
                  style={{ maxWidth: "200px", maxHeight: "200px" }}
                />
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button disabled={loading} variant="primary" onClick={UpdateImageCurrency}>
            {t("panel.save")}
          </Button>
          <Button variant="danger" onClick={handleCloseImage}>
            {t("panel.cancel")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CurrencyAddAndUpdate;

const labelInput = (label, value, onChange) => (
  <div className="mb-3">
    <label className="form-label">{label}</label>
    <input
      type="text"
      className="form-control py-2"
      value={value}
      onChange={(e) => onChange(e.target.value)}
    />
  </div>
);

