import React, { useContext } from "react";
import IconsGen from "../icons_generator/IconsGen";
import { useParams } from "react-router-dom";
import AxiosController from "../authentication/AxiosController";
import { useTranslation } from "react-i18next";
import { UserContext } from "../contexts/UserContext";

const CartItem = ({
  quantity,
  originalPrice,
  marketerPrice,
  altName,
  thumbnail,
  cartId,
  productId,
  cart,
  handleCart,
  handleLoader,
  offer = null,
  get_cart,
  is_product_discount
}) => {
  const [_] = useTranslation("global");
  const { slug, lang } = useParams();
  const token = localStorage.getItem("token");
  const { userData } = useContext(UserContext);

  const updateCart = (newQuantity, price = 0) => {
    handleLoader(true);
    AxiosController.put(
      `api/${slug}/${lang}/update-cart/`,
      { cartId, productId, newQuantity, price },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
      .then((res) => {
        handleLoader(false);
      })
      .catch((e) => {
        handleLoader(false);
      });
  };

  const updateLocalCart = (newQuantity, price = 0, handleCart) => {
    handleCart(
      cart.map((value) => {
        if (value.id === cartId) {
          value.price = price !== 0 ? price : value.price;
          value.quantity = newQuantity;
        }
        return value;
      })
    );
  };

  function removeCartItem() {
    handleLoader(true);
    AxiosController.put(
      `api/${slug}/${lang}/delete-cart/`,
      { cartId, productId },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
      .then((res) => {
        get_cart();
        handleLoader(false);
      })
      .catch((e) => {
        handleLoader(false);
      });
  }

  return (
    <div
      className={`box d-flex flex-column ${
        lang === "en" ? "flex-md-row-reverse" : "flex-md-row"
      } justify-content-between p-4 align-items-center`}
      style={{
        border: "1px solid rgba(239, 237, 237, 1)",
        borderRadius: "7px",
      }}
    >
      <img
        src={thumbnail}
        style={{ borderRadius: "7px" }}
        width="160px"
        alt=""
      />
      <div className="price-info-cart d-flex flex-column align-items-center gap-2">
        <div>
          <p style={{ color: "rgba(89, 198, 201, 1)" }}>
            {_("home.originalprice")}
          </p>
          <p>
            {userData?.level_data && userData?.level_data?.commission ? (
              <del className="text-secondary">{originalPrice}</del>
            ) : (
              originalPrice
            )}
            {altName}
          </p>
        </div>
        {userData?.level_data && userData?.level_data?.commission ? (
          <div>
            <p style={{ color: "rgba(89, 198, 201, 1)" }}>
              {_("home.levelPrice")}
            </p>
            <p className="ecombo-secondary">
              {originalPrice - (userData?.level_data?.commission ?? 0)}{" "}
              {altName}
            </p>
          </div>
        ) : (
          ""
        )}
      </div>

      <div className="price-info-cart d-flex flex-column align-items-center gap-2">
        <p style={{ color: "rgba(243, 168, 55, 1)" }}>{_("home.pieceprice")}</p>
        <input
          type="text"
          className="form-control"
          id="exampleInputEmail1"
          value={marketerPrice}
          onChange={(e) => {
            if (
              e.target.value >=
              originalPrice - (userData?.level_data?.commission ?? 0)
            ) {
              updateCart(quantity, e.target.value);
            }
            updateLocalCart(quantity, e.target.value, handleCart);
            // handleMarkPrice(e.target.value);
          }}
          aria-describedby="emailHelp"
          style={{ textAlign: "center", maxWidth: "100px" }}
        />
      </div>
      <div className="price-info-cart d-flex flex-column align-items-center gap-2">
        <p style={{ color: "rgba(89, 198, 201, 1)" }}>{_("home.quantity")}</p>
        <div className="q-div d-flex align-items-center gap-2">
          {offer ||is_product_discount? "" : (
            <div
              onClick={() => {
                updateCart(quantity + 1, marketerPrice);
                updateLocalCart(quantity + 1, 0, handleCart);
                // handleq(q + 1);
              }}
            >
              <IconsGen icon_name={"addCircle"} />
            </div>
          )}
          <p>{quantity}</p>
          { offer ||is_product_discount ? "" :  (
            <div
              onClick={() => {
                updateCart(quantity - 1 > 0 ? quantity - 1 : 1, marketerPrice);
                updateLocalCart(
                  quantity - 1 > 0 ? quantity - 1 : 1,
                  0,
                  handleCart
                );
                // handleq(q - 1 > 0 ? q - 1 : 1);
              }}
            >
              <IconsGen icon_name={"minusCircle"} />
            </div>
          )}
        </div>
      </div>
      <div className="price-info-cart d-flex flex-column align-items-center gap-2">
        <p style={{ color: "rgba(243, 168, 55, 1)" }}>
          {_("home.totalcommission")}
        </p>
        <p>
          {quantity * marketerPrice -
            quantity *
              (originalPrice - (userData?.level_data?.commission ?? 0))}
          {altName}
        </p>
      </div>
      <div className="price-info-cart d-flex flex-column align-items-center gap-2 align-self-start">
        <div onClick={removeCartItem}>
          <IconsGen icon_name={"closeCart"} />
        </div>
      </div>
    </div>
  );
};

export default CartItem;
