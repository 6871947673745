import React, {  useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, Modal } from "react-bootstrap";
import AxiosController from "../authentication/AxiosController";
import { notifyError, notifySuccess } from "../toastify/toastify";

const AllowedRoleAddAndUpdate = ({ show,  setShow ,  id ,  update,  data,  fetchAllowedRole }) => {
  const { slug, lang , currencyId } = useParams();
  const token = localStorage.getItem("token");
  const { t } = useTranslation("global");
  const [loading, handleLoader] = useState(false); 
  const [userId, setUserId] = useState(''); 

  useEffect(() => {
    if (data) { 
      setUserId(data?.userId)
    }
  }, [data]);
  const createOrUpdateAllowedRole = () => {
    handleLoader(true);
    const url = update
      ? `api/${slug}/${lang}/allowd-currencies/add-private-user/${parseInt(currencyId)}/${userId}`
      : `api/${slug}/${lang}/allowd-currencies/add-private-user/${parseInt(currencyId)}/${userId}`;
    const method = update ? "put" : "post";

    AxiosController[method](
      url,{},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((res) => {
        notifySuccess(t("panel.updateSuccessMsg"))
        fetchAllowedRole();
        handleLoader(false);
        handleClose();
      })
      .catch((e) => {         
        if (e.response && e.response.data && e.response.data.error) {
          const errors = e.response.data.error;
          Object.entries(errors).forEach(([key, value]) => {
            if (Array.isArray(value)) {
              value.forEach((err) => notifyError(`${key}: ${err}`));
            } else {
              notifyError(`${key}: ${value}`);
            }
          });
        } else if (e.response && e.response.data) {
          notifyError(e.response.data.message);
        } else {
          notifyError("An unexpected error occurred.");
        }
        handleLoader(false);
      });
      
  };
 
  const handleClose = () => {
    setShow(false);
  };
 

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton className="border-0">
          <Modal.Title className="flex-grow-1">
            {update ? t("panel.update") : t("panel.add")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body> 
          {labelInput(t("panel.user"), userId , setUserId)} 
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button disabled={loading|| !userId} variant="primary" onClick={createOrUpdateAllowedRole}>
            {t("panel.save")}
          </Button>
          <Button variant="danger" onClick={handleClose}>
            {t("panel.cancel")}
          </Button>
        </Modal.Footer>
      </Modal> 
    </>
  );
};

export default AllowedRoleAddAndUpdate;

const labelInput = (label, value, onChange) => (
  <div className="mb-3">
    <label className="form-label">{label}</label>
    <input
      type="text"
      className="form-control py-2"
      value={value}
      onChange={(e) => onChange(e.target.value)}
    />
  </div>
);

