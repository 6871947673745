import React from "react";
import { useTranslation } from "react-i18next";
import Fintech from "../../imgs/static_home_images/Fintech-logo.png";
import wasla from "../../imgs/static_home_images/wasla-logo.png";
import Fe_el_mal from "../../imgs/static_home_images/Fe-el-mal-logo.png";
import Enterprise from "../../imgs/static_home_images/Enterprise-logo.png";
import { Link, useParams } from "react-router-dom";

export default function Says() {
  const [_] = useTranslation("global");
  const { lang } = useParams();

  return (
    <section
      dir={lang === "en" ? "ltr" : "rtl"}
      className="py-5 overflow-hidden"
    >
      <div className="container text-center mb-5">
        <h2 className="ecombo-primary fw-bold">
          {_("static_home.says.title")}
        </h2>
        <p className="lead text-muted">{_("static_home.says.description")}</p>
      </div>

      {/* Logos Section */}
      <div className="container mt-5">
        <div className="d-flex flex-wrap align-items-center justify-content-center gap-5  my-5">
          <Link
            to="https://fintechgate.net/2023/06/13/%D8%A7%D9%8A%D9%83%D9%88%D9%85%D8%A8%D9%88-%D9%84%D9%84%D8%AA%D8%AC%D8%A7%D8%B1%D8%A9-%D8%A7%D9%84%D8%A5%D9%84%D9%83%D8%AA%D8%B1%D9%88%D9%86%D9%8A%D8%A9-%D8%AA%D8%B3%D8%AA%D9%87%D8%AF/"
            target="_blank"
          >
            <img
              src={Fintech}
              alt={_("static_home.says.fintechAlt")}
              width={150}
              height={60}
              data-aos="fade-up"
            />{" "}
          </Link>
          <Link
            to="https://waslaeqtsadea.com/%D8%A7%D9%8A%D9%83%D9%88%D9%85%D8%A8%D9%88-%D8%AA%D8%B3%D8%AA%D9%87%D8%AF%D9%81-%D8%A7%D9%84%D8%AD%D8%B5%D9%88%D9%84-%D8%B9%D9%84%D9%89-%D8%AC%D9%88%D9%84%D8%A9-%D8%A7%D8%B3%D8%AA%D8%AB%D9%85%D8%A7/"
            target="_blank"
          >
            <img
              src={wasla}
              alt={_("static_home.says.waslaAlt")}
              width={150}
              height={60}
              data-aos="fade-up"
              data-aos-delay="100"
            />{" "}
          </Link>
          <Link
            to="https://almalnews.com/%d8%ae%d8%b7%d8%a9-%d9%84%d9%84%d8%aa%d9%88%d8%b3%d8%b9-%d9%81%d9%8a-%d8%a7%d9%84%d8%b3%d8%b9%d9%88%d8%af%d9%8a%d8%a9-%d9%88%d8%b9%d9%85%d8%a7%d9%86-%d9%85%d9%86%d8%b5%d8%a9-%d8%a7%d9%8a%d9%83/"
            target="_blank"
          >
            <img
              src={Fe_el_mal}
              alt={_("static_home.says.feElMalAlt")}
              width={150}
              height={60}
              data-aos="fade-up"
              data-aos-delay="200"
            />{" "}
          </Link>
          <Link
            to="https://enterprise.news/egypt/en/news/story/116c5654-3538-40ba-8ffe-098e842b269f/meet-our-founder-of-the-week:%253cmark%253e-fares%253c%2Fmark%253e%253cmark%253e-senior%253c%2Fmark%253e,-founder-and-ceo-of-e-combo"
            target="_blank"
          >
            <img
              src={Enterprise}
              alt={_("static_home.says.enterpriseAlt")}
              width={150}
              height={60}
              data-aos="fade-up"
              data-aos-delay="300"
            />
          </Link>
        </div>
      </div>
    </section>
  );
}
