import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Pagination, Table } from "react-bootstrap";
import { Helmet } from "react-helmet";
import AxiosController from "../../../authentication/AxiosController";

const ProductDiscounts = () => {
  
  const { slug, lang } = useParams();
  const [_] = useTranslation("global");
  const box_head_dir = lang === "en" ? "ltr" : "rtl";
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(false);
  const [allData, setAllData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const get_product_discounts = (page = 1) => {
    setLoading(true);
    AxiosController.get(`/apiv2/${slug}/${lang}/product-discounts`, {
      params: { page },
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        setLoading(false);
        setAllData(res?.data?.data || []);
        setTotalPages(res?.data?.total_pages || 1);
      })
      .catch(() => {
        setLoading(false);
        setAllData([]);
      });
  };

  useEffect(() => {
    get_product_discounts(currentPage);
  }, [currentPage]);

  const changePage = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  return (
    <div className="flex-grow-1 stats-component" dir={box_head_dir}>
      <Helmet>
        <title>{_("panel.productDiscounts")}</title>
      </Helmet>
      <div className=" d-flex flex-column flex-md-row py-2 justify-content-between flex-wrap">
        <h3 className="fs-2 mb-4" style={{ color: "rgba(89, 198, 201, 1)" }}>
          {_("panel.productDiscounts")}
        </h3>
      </div>
      <div className="  my-3 d-flex flex-column gap-2 px-2 position-relative">
        <div className="row">
          <div
            style={{ borderRadius: "40px" }}
            className={`table-responsive border my-3 d-flex ${
              lang === "en" ? "flex-row" : "flex-row-reversed"
            } align-items-center`}
          >
            <Table responsive className="table table-borderless">
              <thead>
                <tr className="text-center">
                  <th>#</th>
                  <th>{_("panel.productTitle")}</th>
                  <th>{_("panel.status")}</th>
                  <th>{_("panel.offersCount")}</th>
                </tr>
              </thead>
              <tbody>
                {allData.length > 0 ? (
                  allData.map((product, index) => (
                    <tr key={product.product_id}>
                      <td>{index + 1}</td>
                      <td>
                        <span className="fw-bold">{product.product_id}</span>
                        <br />
                        {product.title}
                      </td>
                      <td>
                        {product.is_active
                          ? _("panel.Active")
                          : _("panel.Inactive")}
                      </td>
                      <td>{product.offers_count}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="4" className="text-center">
                      No Data to show
                    </td>{" "}
                  </tr>
                )}
              </tbody>
            </Table>
          </div>

          {allData.length > 0 && (
            <Pagination className="m-auto flex-wrap d-flex align-items-center justify-content-center">
              <Pagination.First
                onClick={() => changePage(1)}
                disabled={currentPage === 1}
              />
              <Pagination.Prev
                onClick={() => changePage(currentPage - 1)}
                disabled={currentPage === 1}
              />
              {[...Array(totalPages)].map((_, index) => (
                <Pagination.Item
                  key={index + 1}
                  active={currentPage === index + 1}
                  onClick={() => changePage(index + 1)}
                >
                  {index + 1}
                </Pagination.Item>
              ))}
              <Pagination.Next
                onClick={() => changePage(currentPage + 1)}
                disabled={currentPage === totalPages}
              />
              <Pagination.Last
                onClick={() => changePage(totalPages)}
                disabled={currentPage === totalPages}
              />
            </Pagination>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductDiscounts;
