import React, { useEffect, useState } from "react";
import AxiosController from "../../../authentication/AxiosController";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Button, Pagination } from "react-bootstrap";
import Loader from "../../../loader/Loader";
import IconsGen from "../../../icons_generator/IconsGen";
import ProductsStockTableRow from "./ProductsStockTableRow";
import { useTranslation } from "react-i18next";
import SearchComponent from "./SearchComponent";
import * as XLSX from "xlsx";
import { Helmet } from "react-helmet";
import PaginationComponent from "../../../reuseable/PaginationComponent";
// import PaginationComponent from "../../../reuseable/pagginationComponent";
const get_products_stock = (
  slug,
  lang,
  token,
  handleProductsStock,
  page,
  handleLoader,
  productCode,
  sku,
  supplier,
  warehouse,
  startDate,
  endDate,
  sellerId,
  handleProductsStockLog
) => {
  AxiosController.get(`api/${slug}/${lang}/get-products-stocks/${page}`, {
    headers: { Authorization: `Bearer ${token}` },
    params: {
      product_code: productCode,
      sku,
      supplier,
      warehouse,
      start_date: startDate,
      end_date: endDate,
      seller_id: sellerId,
    },
  })
    .then((res) => {
      handleProductsStock(res.data[0]);
      handleProductsStockLog(res.data[1]);
      handleLoader(false);
    })
    .catch((e) => {
      handleLoader(false);
    });
};

const ProductsStock = () => {
  const { slug, lang } = useParams();
  const token = localStorage.getItem("token");
  const role = localStorage.getItem("role");
  const [_] = useTranslation("global");
  const [productsStock, handleProductsStock] = useState([]);
  const [productsStockLog, handleProductsStockLog] = useState([]);
  const [productsStockLabel, handleProductsStockLabel] = useState([]);
  const [loading, handleLoader] = useState(false);
  const [search, setSearch] = useState(false);  
  const [page, handlePage] = useState(1);
  const location = useLocation();
  const Navigate = useNavigate();
  const urlParams = new URLSearchParams(location.search);

  const updateUrlParam = (param, value) => {
    urlParams.set(param, value);
    handlePage(value);
    Navigate(`?${urlParams.toString()}`);
  };
  const get_stock_labels = (
    product_code,
    sku,
    supplier,
    warehouse,
    start_date,
    end_date,
    sellerId
  ) => {
    handleLoader(true);

    AxiosController.get(`api/${slug}/${lang}/warehouse/products-overview`, {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        product_code,
        sku,
        supplier,
        warehouse,
        start_date,
        end_date,
        seller_id: sellerId,
      },
    })
      .then((res) => {
        handleLoader(false);
        handleProductsStockLabel(res?.data);
      })
      .catch((e) => {
        handleLoader(false);
      });
  };

  const get_products_stock2 = (all = false) => {
    const productCode = urlParams.get("product_code") || "";
    const sku = urlParams.get("sku") || "";
    const supplier = urlParams.get("supplier") || "";
    const warehouse = urlParams.get("warehouse") || "";
    const start_date = urlParams.get("start_date") || "";
    const end_date = urlParams.get("end_date") || "";
    const seller_id = urlParams.get("seller_id") || "";

    handleLoader(true);

    AxiosController.get(`api/${slug}/${lang}/get-variants-stocks/${page}`, {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        all: all ? 1 : 0,
        product_code: productCode,
        sku,
        supplier,
        warehouse,
        start_date,
        end_date,
        seller_id,
      },
    })
      .then((res) => {
        if (!all) {
          handleLoader(false);
        } else {
          const data = res.data.map((order) => {
            const averagePrice =
              parseFloat(order["price"]) && role == "admin"
                ? parseFloat(order["price"]).toFixed(2)
                : 0;
            const remaining = parseFloat(order["left"]);
            const profit = parseFloat(order["commission"])
              ? parseFloat(order["commission"]).toFixed(2)
              : 0;

            // Calculate المتبقي * متوسط السعر
            const totalValue = remaining * averagePrice;

            return {
              "كود المنتج": order["product_code"],
              SKU: order["sku"],
              "عنوان المنتج": order["product_title"],
              المتبقي: remaining,
              "متوسط السعر": averagePrice,
              "ربحية التاجر": profit,
              "اسم التاجر": `${order["seller_first_name"]} ${order["seller_last_name"]}`,
              "مع شركات الشحن": order["with_courier"],
              "المتبقي * متوسط السعر": totalValue.toFixed(2), // New column
            };
          });

          // Calculate totals for numeric columns
          const totalLeft = data.reduce(
            (sum, row) => sum + parseFloat(row["المتبقي"]),
            0
          );
          const totalPrice = data.reduce(
            (sum, row) => sum + parseFloat(row["متوسط السعر"]),
            0
          );
          const totalCommission = data.reduce(
            (sum, row) => sum + parseFloat(row["ربحية التاجر"]),
            0
          );
          const totalCourier = data.reduce((sum, row) => {
            const value = parseFloat(row["مع شركات الشحن"]);
            return sum + (isNaN(value) ? 0 : value);
          }, 0);
          const totalValue = data.reduce(
            (sum, row) => sum + parseFloat(row["المتبقي * متوسط السعر"]),
            0
          );

          // Create an object to hold totals
          const totalsRow = {
            "كود المنتج": "Total",
            SKU: "",
            "عنوان المنتج": "",
            المتبقي: totalLeft,
            "متوسط السعر": totalPrice.toFixed(2),
            "ربحية التاجر": totalCommission.toFixed(2),
            "اسم التاجر": "",
            "مع شركات الشحن": totalCourier,
            "المتبقي * متوسط السعر": totalValue.toFixed(2),
          };

          // Insert totals row at the top of the data array
          data.unshift(totalsRow);

          // Create worksheet and add data
          let worksheet = XLSX.utils.json_to_sheet(data);
          const workbook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
          XLSX.writeFile(workbook, "Products-Stock.xlsx");

          handleLoader(false);
        }
      })
      .catch((e) => {
        handleLoader(false);
      });
  };

  useEffect(() => {
    handleLoader(true);
    const nextPage = parseInt(urlParams.get("page"), 10) || 1;
    const productCode = urlParams.get("product_code") || "";
    const sku = urlParams.get("sku") || "";
    const supplier = urlParams.get("supplier") || "";
    const startDate = urlParams.get("start_date") || "";
    const endDate = urlParams.get("end_date") || "";
    const sellerId = urlParams.get("seller_id") || "";
    handlePage(nextPage);
    const warehouse = urlParams.get("warehouse") || "";
    get_stock_labels(
      productCode,
      sku,
      supplier,
      warehouse,
      startDate,
      endDate,
      sellerId
    );
    get_products_stock(
      slug,
      lang,
      token,
      handleProductsStock,
      nextPage,
      handleLoader,
      productCode,
      sku,
      supplier,
      warehouse,
      startDate,
      endDate,
      sellerId,
      handleProductsStockLog
    );
  }, [slug, lang, location.search, handleLoader, handleProductsStockLabel]);

  return (
    <div
      className={`table-responsive tables-container my-3 d-flex flex-column position-relative ${
        lang === "en" ? "flex-row" : "flex-row-reversed"
      } align-items-center`}
    >
      <Helmet>
        <title>{_("panel.productsStock")}</title>
      </Helmet>
      <Button
        className="fs-5 px-5 w-25 align-self-start"
        style={{
          backgroundColor: "rgb(89, 198, 201)",
          border: "none",
          borderRadius: "7px",
        }}
        onClick={() => {
          setSearch(!search);
        }}
      >
        {_("panel.search")}
      </Button>
      {search ? <SearchComponent /> : ""}
      <Loader active={loading} />
      <div
        className="tables-container d-flex flex-column px-2 w-100"
        style={{ position: "relative", flexGrow: 3 }}
      >
        <div
          className={`header d-flex flex-column  flex-md-row ${
            lang === "en" ? "flex-row" : "flex-row"
          } gap-3 align-items-center justify-content-between`}
        >
          <h3 className="fs-1" style={{ color: "rgba(89, 198, 201, 1)" }}>
            {_("panel.productsStock")}
          </h3>
          <button
            className="btn"
            style={{ border: "1px solid #172B4D", color: "#172B4D" }}
            onClick={() => get_products_stock2(true)}
          >
            <IconsGen icon_name={"excel"} /> {_("panel.downloadExcel")}
          </button>
        </div>
        <div className="footer d-flex flex-column flex-grow-1">
          {productsStock.data && (
            <ProductsStockTableRow
              payments_list={productsStock.data}
              productsStockLog={productsStockLog}
              handleLoader={handleLoader}
              labels={productsStockLabel}
            />
          )}
        </div>
      </div>

      <PaginationComponent
        currentPage={page}
        totalPages={productsStock["last_page"]}
        onPageChange={(newPage) => updateUrlParam("page", newPage)}
        lang={lang}
      />
    </div>
  );
};

export default ProductsStock;
