import React, { useEffect } from "react";
import logo from "../../imgs/Logo_Ecombo 1.png";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LangSelector from "../languages/LangSelector";

import { Box, Text, Center } from "@mantine/core";
import { IconCheck, IconX } from "@tabler/icons-react";

import network from "../../imgs/network.png";
import { Helmet } from "react-helmet";

function PasswordRequirement({ meets, label }) {
  return (
    <Text component="div" c={meets ? "teal" : "red"} mt={5} size="sm">
      <Center inline>
        {meets ? (
          <IconCheck size="0.9rem" stroke={1.5} />
        ) : (
          <IconX size="0.9rem" stroke={1.5} />
        )}
        <Box ml={7}>{label}</Box>
      </Center>
    </Text>
  );
}

const Choice = () => {
  const { lang, slug } = useParams();
  const Navigate = useNavigate();

  const [_] = useTranslation("global");
  const box_head_dir = lang === "en" ? "ltr" : "rtl";

  useEffect(() => {}, []);
  return (
    <div
      className=" d-flex flex-column w-100  "
      dir={box_head_dir}
      style={{ height: "100vh" }}
    >
      <div
        className="headLogo d-flex justify-content-center w-100 p-4 "
        style={{ background: "white" }}
      >
        <Helmet>
          <title>{_("panel.انشئ حسابك الان في ايكومبو")}</title>
        </Helmet>
        <div className="d-flex align-items-center">
          <LangSelector lang={lang} />
        </div>
        <img
          alt="logo"
          style={{ cursor: "pointer" }}
          onClick={() => Navigate(`/`)}
          src={logo}
          width={"250px"}
        />
      </div>
      <div className="body flex-fill-remaining h-100">
        <div
          className="cover-left d-flex flex-column flex-fill-remaining align-items-center justify-content-center gap-5 align-items-center px-5  d-flex h-100"
          style={{ flexGrow: 3, color: "white" }}
        >
          <img
            src={network}
            width="400px"
            alt="images/network"
            style={{ position: "absolute", zIndex: "0", left: 0, bottom: 0 }}
            className="d-none d-md-block"
          />

          <h1>{_("panel.انشئ حسابك الان في ايكومبو")}</h1>
          <div className="d-none d-md-flex flex-md-row  flex-row justify-content-between pt-5 gap-3">
            <div className="row">
              <div className="col-6">
                <button
                  className="btn w-100 fs-5 text-white rounded-3 px-3 py-2"
                  onClick={() => Navigate("../register/seller")}
                  style={{
                    color: "#6EBCC2",
                    position: "relative",
                    zIndex: "10",
                    background: "#F3A837",
                  }}
                >
                  {_("registeration.seller")}
                </button>
                <p className="text-center">{_("registeration.sellerTitle")}</p>
              </div>

              <div className="col-6">
                <button
                  button
                  className="btn w-100 fs-5 text-white rounded-3 px-3 py-2"
                  onClick={() => Navigate("../register/marketer")}
                  style={{ color: "#6EBCC2", background: "#F3A837" }}
                >
                  {_("registeration.marketer")}
                </button>
                <p className="text-center">
                  {_("registeration.marketerTitle")}
                </p>
              </div>
              <div className="col-6 m-auto py-4 d-flex align-items-center justify-content-center">
                <Link
                  // className="btn w-100 fs-5 text-white rounded-3 px-3 py-2"
                  className="text-decoration-none text-dark "
                  to={"../login"}
                >
                  {_("panel.login")}
                </Link>
              </div>
            </div>
          </div>
          <div className="d-flex d-md-none flex-column  flex-row justify-content-between align-items-center pt-5 gap-5">
            <button
              className="btn w-100"
              style={{ color: "#6EBCC2", background: "#F3A837" }}
            >
              <Link
                to="../register/seller"
                className="fs-5 text-white rounded-3 px-3 py-2 text-decoration-none"
              >
                {_("panel.seller")}
              </Link>
            </button>
            <button
              className="btn w-100"
              style={{ color: "#6EBCC2", background: "#F3A837" }}
            >
              <Link
                to="../register/marketer"
                className="fs-5 text-white rounded-3 px-3 py-2  text-decoration-none"
              >
                {_("panel.affiliate")}
              </Link>
            </button>
            <div className="m-auto py-4 d-flex align-items-center justify-content-center">
              <Link
                // className="btn w-100 fs-5 text-white rounded-3 px-3 py-2"
                className="text-decoration-none text-dark "
                to={"../login"}
              >
                {_("panel.login")}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Choice;
