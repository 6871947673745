import React, { useEffect } from "react";
import { Offcanvas, Row, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useParams, Link } from "react-router-dom";
import CartItem from "./CartItem";
import "./index.css";
import Loader from "../loader/Loader";

const CartOffCanvas = ({ show, handleClose,  cart, handleCart, loading }) => {
  const [_] = useTranslation("global");
  const { lang, slug } = useParams();
  const role = localStorage.getItem("role");

  useEffect(() => {}, [cart]);

  return (
    <Offcanvas
      show={show}
      onHide={handleClose}
      placement={lang === "en" ? "start" : "end"}
    >
      <Offcanvas.Header closeButton dir={lang === "ar" ? "rtl" : "ltr"}>
        <Offcanvas.Title style={{ fontSize: "1.4rem", color: "#172B4D" }}>
          {_("home.cart")}
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body className="d-flex flex-column gap-3">
        <Loader active={loading} />
        {cart.map((item) => {
          return (
            <CartItem
              key={item?.id}
              title={item?.title}
              thumbnail={item?.thumbnail}
              quantity={item?.quantity}
              price={item?.price}
              is_product_discount={item?.is_product_discount}
              offer_id={item?.offer_id}
              altName={item?.alt_name}
              cartId={item?.id}
              productId={item?.p_id}
              handleCart={handleCart}
              cart={cart} 
            />
          );
        })}
        <Row></Row>
        <Link
          to={`/${slug}/${lang}/${
            role == "fulfillment" ? "place-order" : "cart"
          }`}
          onClick={handleClose}
          className="w-100"
        >
          <Button className="w-100" style={buttonStyles}>
            {_("home.showcart")}
          </Button>
        </Link>
      </Offcanvas.Body>
    </Offcanvas>
  );
};
const buttonStyles = {
  border: "none",
  padding: ".5rem .76rem",
  borderRadius: "7px",
  backgroundColor: "rgb(89, 198, 201)",
  fontWeight: "bold",
  fontSize: "1.2rem",
};
export default CartOffCanvas;
